import React from 'react'
import H2 from '../../core/H2';

export default function LogoCloud({ allWpLogo: { nodes }, title }) {
  return (
    <div className="bg-transperent mt-24">
      <div data-aos="fade-up">
        {title && <H2 title={title} className={`text-center`} />}
      </div>
      <div className="max-w-screen-xl mx-auto py-14 md:px-10 px-6 lg:px-8">
        <div
          className="grid grid-cols-3 gap-4   lg:gap-0 md:gap-y-2 md:gap-x-12 md:grid-cols-6 lg:grid-cols-5"
          data-aos="fade-up"
        >
          {nodes.map(({ LogoCPT: { image: { mediaItemUrl, altText, mediaDetails, srcSet } } }) => (
            <div
              key={Math.random()}
              className="col-span-1 flex justify-center md:col-span-2 lg:col-span-1 opacity-100"
            >
              <div className="lg:w-44 md:w-40 w-24 h-auto">
                <img
                  className="lg:p-4 md:p-4 p-2"
                  src={mediaItemUrl}
                  // srcset={srcSet}
                  width={mediaDetails?.width}
                  height={mediaDetails?.height}
                  alt={altText}
                  title="Logo Cloud"
                  loading="lazy"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
